<template>
  <div
    class="flex flex-col items-center overflow-clip"
    :class="{ 'pt-72': isHeaderFixed && screen.md }"
  >
    <v-header />
    <v-container
      class="flex grow justify-center pb-20"
      :class="containerClass"
    >
      <slot />
    </v-container>
    <v-footer />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    pageBgClass?: string
  }>(),
  {
    pageBgClass: "bg-fafafc",
  }
)

const route = useRoute()

const pageBgClass = computed(() => (route.meta.pageBgClass as string) || props.pageBgClass)
provide("pageBgClass", pageBgClass)

useHead({
  bodyAttrs: {
    class: pageBgClass,
  },
})

const screen = useScreen()

const containerClass = computed(() => route.meta.containerClass)
const isHeaderFixed = computed(() => route.meta.isHeaderFixed ?? true)
</script>
