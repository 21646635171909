<template>
  <v-dropdown v-if="!screen.md">
    <template #default="{ toggleDropdown, isDropdownVisible }">
      <v-user-menu-button
        :is-dropdown-visible="isDropdownVisible"
        @click="toggleDropdown"
      />
    </template>

    <template #options="{ hideDropdown }">
      <template v-if="user.isAdmin">
        <v-dropdown-item
          icon="material-symbols:admin-panel-settings-outline"
          @click="navigateTo('/admin'), hideDropdown()"
        >
          Панель управления
        </v-dropdown-item>
      </template>
      <v-dropdown-item
        icon="iconoir:settings"
        @click="modals.show('user-settings'), hideDropdown()"
      >
        Настройки
      </v-dropdown-item>
      <v-dropdown-item
        v-if="user.isPremiumActive"
        icon="iconoir:credit-card-2"
        @click="navigateTo('/plan/active'), hideDropdown()"
      >
        Подписка
      </v-dropdown-item>
      <v-dropdown-separator />
      <v-dropdown-item
        icon="iconoir:log-out"
        variant="danger"
        @click="user.logout(), hideDropdown()"
      >
        Выйти
      </v-dropdown-item>
    </template>
  </v-dropdown>
  <template v-else>
    <v-bottom-sheet>
      <template #trigger="{ showBottomSheet }">
        <v-user-menu-button
          v-bind="$attrs"
          @click="showBottomSheet"
        />
      </template>

      <template #default="{ hideBottomSheet }">
        <v-bottom-sheet-item
          v-if="user.isAdmin"
          icon="material-symbols:admin-panel-settings-outline"
          @click="navigateTo('/admin'), hideBottomSheet()"
        >
          Панель управления
        </v-bottom-sheet-item>
        <v-bottom-sheet-item
          v-if="user.isPremiumActive"
          icon="iconoir:credit-card-2"
          @click="navigateTo('/plan/active'), hideBottomSheet()"
        >
          Подписка
        </v-bottom-sheet-item>
        <v-bottom-sheet-item
          icon="iconoir:log-out"
          variant="danger"
          @click="user.logout(), hideBottomSheet()"
        >
          Выйти
        </v-bottom-sheet-item>
        <v-bottom-sheet-item
          icon="tabler:trash"
          variant="danger"
          @click="modals.show('delete-user'), hideBottomSheet()"
        >
          Удалить аккаунт
        </v-bottom-sheet-item>
      </template>
    </v-bottom-sheet>
  </template>
</template>

<script setup lang="ts">
const user = useUserStore()
const screen = useScreen()
const modals = useModalsStore()
</script>
