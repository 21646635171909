<template>
  <v-container
    class="flex justify-between py-16 md:left-0 md:right-0 md:top-0 md:z-30 md:items-center"
    :class="{
      [bgClass]: true,
      'md:fixed': isHeaderFixed,
    }"
  >
    <button
      v-if="backLink"
      @click="onBackLinkClick"
    >
      <Icon
        name="iconoir:nav-arrow-left"
        size="24px"
      />
    </button>
    <v-mobile-nav
      v-else
      class="hidden md:block"
      :class="{
        'pointer-events-none opacity-0': hideMobileNav || !user.isAuthorized,
      }"
    />

    <nuxt-link to="/">
      <v-logo />
    </nuxt-link>

    <v-user-menu :class="{ 'pointer-events-none opacity-0': !user.isAuthorized }" />
  </v-container>
</template>

<script setup lang="ts">
const route = useRoute()
const router = useRouter()

const user = useUserStore()

const isHeaderFixed = computed(() => route.meta.isHeaderFixed ?? true)
const bgClass = inject("pageBgClass") as Ref<string>

const backLink = computed(() => {
  if (route.name === "portfolioInstrument") {
    return `/portfolio/${route.params.id}?tab=instruments`
  }
})

function onBackLinkClick() {
  // easiest way to preserve scroll position
  if (window.history.state?.back) {
    router.back()
    return
  }

  navigateTo(backLink.value)
}

const hideMobileNav = computed(() => Boolean(route.meta.hideMobileNav))
</script>
