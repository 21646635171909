<template>
  <div>
    <button @click="isMenuVisible = true">
      <Icon
        name="iconoir:menu"
        size="24px"
      />
    </button>

    <Transition name="slide-from-left">
      <div
        v-if="isMenuVisible"
        v-on-click-outside="() => (isMenuVisible = false)"
        class="fixed bottom-0 left-0 top-0 z-40 flex w-full max-w-[320px] flex-col overflow-auto bg-white pt-16"
      >
        <div class="flex items-center px-16">
          <button @click="isMenuVisible = false">
            <Icon
              name="iconoir:cancel"
              size="30px"
            />
          </button>
          <v-logo class="mx-auto" />
        </div>

        <p class="mb-6 mt-24 px-16 font-medium opacity-60">Портфели:</p>
        <ul class="mb-16 px-16">
          <li
            v-for="portfolio in portfolioStore.portfolios"
            :key="portfolio.id"
            class="break-words text-18 font-medium"
          >
            <NuxtLink
              class="inline-block py-10 text-inherit"
              :to="`/portfolio/${portfolio.id}`"
              @click="isMenuVisible = false"
            >
              <Icon
                :name="portfolio.emoji"
                class="relative -top-2"
              />
              {{ portfolio.name }}
            </NuxtLink>
          </li>
        </ul>

        <div class="mt-auto border-t border-e6e7ec px-16">
          <button
            class="py-14 text-18"
            @click="modals.show('create-portfolio'), (isMenuVisible = false)"
          >
            Добавить портфель
            <Icon
              name="iconoir:plus"
              size="24px"
              class="relative -top-2 ml-10"
            />
          </button>
        </div>
      </div>
    </Transition>
    <Transition>
      <div
        v-if="isMenuVisible"
        class="fixed inset-0 z-30 cursor-pointer overflow-auto bg-232335/30 px-20 py-60 md:px-0 md:py-0"
      ></div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components"

const isMenuVisible = ref(false)
const isScrollLocked = useScrollLock(document)

watch(
  isMenuVisible,
  () => {
    isScrollLocked.value = isMenuVisible.value
  },
  { immediate: true }
)

const modals = useModalsStore()

const portfolioStore = usePortfolioStore()
</script>
